import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    CargaNominaConf,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: false,
        buscador: true
    }

    registro = {
        enableCarreras: null,
        universidades: [],
        amount: 3,
        enableNivel: null,
        enableColegio: null,
        enableTelefono: true,
        enableFechaNacimiento: null,
        nombresObligatorio: null,
        apellidoPaternoObligatorio: null,
        apellidoMaternoObligatorio: null,
        carrerasObligatorio: null,
        telefonoObligatorio: null,
        nivelObligatorio: null,
        colegioObligatorio: null,
        fechaNacimientoObligatorio: null
    }
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmall",
                logoMobile: "logoSquare"
            },
            footer: {
                logo: "logoSmallLight",
                logosExtras: [
                    {
                        link: "https://sence.gob.cl/",
                        src: "assets/img/logos/logo-sence.png",
                        title: "Sence",
                        logoXL: true
                    }
                ]
            },
            landing: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/be861b8dda49752e13ede43076e305833 (1) (3) (1).png",
                logo: "logoLargeLight",
                subtitle: "La plataforma educativa que te apoya en tus Exámenes Libres",
                subtitleHasShadow: null,
                logoFooter: "logoSmallLight"
            },
            sharedLayout: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/c389bf5042daf192a4f63a3efc1a6fa93 (1) (3) (1).png",
                logo: "logoLargeLight"
            }
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/pruebas/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                },
                {
                    icon: "dialogos",
                    name: "Dudas del curso",
                    route: "/dudas"
                }
            ],
            profesores: [
                {
                    icon: "editar-prueba",
                    name: "Evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "upload",
                    name: "Corregir",
                    route: "/evaluacion_instancias/subir"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                },
                {
                    icon: "megafono-o",
                    name: "Mis noticias",
                    route: "/noticias"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase predefinido",
                    route: "/plan_clases_predefinidos"
                }
            ]
        },
        showcasesByApp: {
            alumnos: [
                {
                    orden: 0,
                    nombre: "Datos Usuario",
                    columna: 0,
                    selected: true,
                    identificador: "usuario-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Noticias",
                    columna: 0,
                    selected: true,
                    identificador: "noticias-alumnos-showcasebox"
                }
            ],
            profesores: [
                {
                    orden: 0,
                    nombre: "Datos Usuario",
                    columna: 1,
                    selected: true,
                    identificador: "usuario-showcasebox"
                },
                {
                    orden: 0,
                    nombre: "Cursos profesor",
                    columna: 2,
                    selected: true,
                    identificador: "grupo-usuarios-profesor-showcase"
                }
            ]
        },
        menuConfig: {
            tipo: "horizontal",
            enableShowcaseMenuHomeAlumnos: false,
            enableShowcaseMenuHomeDocentes: false
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["Nivelación de estudios"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Lenguaje",
            icono: "lenguaje",
            clase: "lenguaje",
            abreviacion: "Lenguaje",
            asignaturaPlataforma: "Lenguaje",
            evaluacionTipo: ["Nivelación de estudios"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Naturales",
            icono: "ciencias",
            clase: "ciencias",
            abreviacion: "Ciencias Naturales",
            asignaturaPlataforma: "Ciencias Naturales",
            evaluacionTipo: ["Nivelación de estudios"],
            materialVisible: true
        },
        {
            asignatura: "Historia y Ciencias Sociales",
            icono: "historia",
            clase: "historia",
            abreviacion: "Historia y Ciencias Sociales",
            asignaturaPlataforma: "Historia y Ciencias Sociales",
            evaluacionTipo: ["Nivelación de estudios"],
            materialVisible: true
        },
        {
            asignatura: "Inglés",
            icono: "ingles",
            clase: "ingles",
            abreviacion: "Inglés",
            asignaturaPlataforma: "Inglés",
            evaluacionTipo: ["Nivelación de estudios"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "graduatepiloto",
        id: null,
        pais: "chile",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        planClasesRoute: "/plan_clases",
        info: {
            social: {
                facebook: null,
                twitter: null,
                youtube: "https://www.youtube.com/channel/UCVTlxP6KsawoUphZOYmdsYw",
                instagram: null
            },
            soporte: {
                mail: "contacto@ogr.cl",
                telefono: null
            },
            contacto: {
                mail: "Si no puedes acceder escríbenos a contacto@graduate.cl",
                telefono: null
            },
            companyName: "Gradúate",
            companySite: null
        },
        evaluacionTipoAdaptiveDefault: "Nivelación de estudios",
        evaluacionDefault: "Nivelación de estudios",
        resumenGeneral: {
            curriculum: "curricular",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["Nivelación de estudios"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        sesionAlias: "logoSquareLight",
        identificadorUsuarioAlias: null,
        showAyuda: null,
        enableBiblioteca: null,
        showMisCarreras: null,
        disableZopim: true,
        locale: "es-cl"
    } as PlataformaType

    evaluaciones = {
        "Nivelación de estudios": {
            alias: "prueba",
            cursos: [
                {
                    label: "Primer Nivel",
                    clasificacion: "Primer Ciclo"
                },
                {
                    label: "Segundo Nivel",
                    clasificacion: "Segundo Ciclo"
                },
                {
                    label: "Fines Laborales",
                    clasificacion: "Fines Laborales"
                }
            ],
            cursoTipo: "curso gradúate",
            estrellas: [],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: ["eje temático gradúate,sub-eje temático gradúate"],
                methods: "clasificaciones",
                estadisticas: ["eje temático gradúate"],
                referencesTo: {},
                configuration: [
                    {
                        name: "Eje temático",
                        htmlId: "eje_temático gradúate",
                        hasHijos: true,
                        clasificacionTipo: "eje temático gradúate"
                    }
                ],
                cambiarPregunta: [],
                desempenoEvaluacion: "eje temático gradúate",
                clasificacionTiposTablaEspecificaciones: ["sub-eje temático gradúate"]
            },
            cantidadPreguntas: 4,
            rangoCalificacion: {
                max: 400,
                min: 0,
                tickInterval: 25
            },
            clasificacionMaterial: {
                label: "Eje",
                clasificacion: "eje temático gradúate"
            },
            clasificacionDistribucion: "Ejes",
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático",
                    alumnos: true,
                    compuesto: true,
                    profesores: true
                },
                {
                    nombre: "sub-eje temático gradúate",
                    alumnos: true,
                    compuesto: true,
                    profesores: true
                }
            ],
            nivelTipoInstrumentoInformeFinal: [],
            planPersonalClasificacionMateriales: []
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o NUIP",
        invalidLoginId: "Debes incluir un correo electrónico o NUIP válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o NUIP/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }

    cargaNomina = {
        nomina: {
            alumnos: "NominaAlumnoGraduate",
            profesores: "NominaProfeGraduate"
        }
    } as CargaNominaConf
}
